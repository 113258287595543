import { template as template_f509d15ac69242de9904d32ca5d3c843 } from "@ember/template-compiler";
const FKLabel = template_f509d15ac69242de9904d32ca5d3c843(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
